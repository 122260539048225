<template>
  <div class="px-5" :key="componentKey">
    <div
      v-if="!showPage"
      class="d-flex justify-center align-center ma-4 h-80vh"
    >
      <global-loading />
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
const GlobalLoading = () => import("../loadings/GlobalLoading.vue");

export default {
  components: {
    GlobalLoading
  },
  data: () => ({
    showPage: false,
    componentKey: 0 //use to re-render component
  }),
  updated() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.showPage = true;
      }, 500);
    });
  },
  mounted() {
    this.componentKey++;
  }
};
</script>

<style lang="scss" scoped>
.h-80vh {
  height: 80vh;
}
</style>
